

/*--------------------------------------------------------------
# Transitions first load
--------------------------------------------------------------*/

.home-once{
	opacity: 0;
	.project-separator{
		opacity: 0;
	}

}

.home-once-active{
	transition: opacity 2s linear 0.3s;
	.project-separator{
		transition: opacity 2s linear 0.3s;
	}
}

.home-once-to{
	opacity: 1;
	.project-separator{
		opacity: 1;
	}
}

/*--------------------------------------------------------------
# Trantitions pages
--------------------------------------------------------------*/
.barba-leave-active,
.barba-enter-active {
  transition: opacity 0.7s ease, transform 0.7s ease-in-out;
}

.barba-leave{
  opacity: 1;
  transform: translateY(0px);
}
.barba-leave-to{
  opacity: 0;
  transform: translateY(-250px);
}

.barba-enter{
  opacity: 0;
  transform: translateY(150px);
}
.barba-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

