body,
button,
input,
select,
textarea {
    color: $color__black;
    font-family: $font__main;
    font-weight: 300;
    font-size: 16px;
    line-height: $font__line-height-body;
    letter-spacing: 0.05em;
    background-color: $color__white;
}

.page-link:focus {
    box-shadow: none;
}

p {
    font-family: $font__main;

    a {
        transition: $transition__color-link;
    }
}

.section-text-black {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font__heading;
        color: $color__black;
    }
    
    p {
        color: $color__black;
    }
}

.section-text-white {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $color__white;
    }
}

/*--------------------------------------------------------------
## Heading
--------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font__heading;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0.75rem;
    clear: both;
    line-height: $font__line-height-heading;
    letter-spacing: 0.05em;

    &.sans {
        font-family: $font__main;
        line-height: $font__line-height-body;
    }
}

h1 {
    font-size: 2.25em;

    @include media-breakpoint-up(md) {
        font-size: 5em;
    }
}

h2 {
    font-size: 2em;

    @include media-breakpoint-up(md) {
        font-size: 4em;
    }
    
    @include media-breakpoint-up(lg) {
        font-size: 5em;
    }
}

h3 {
    font-size: 1.6666em;
}

h4 {
    font-size: 1.3333em;
}

h5 {
    font-size: 1.2em;
}

h6 {
    font-size: 1.0666em;
}

/*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/
b,
strong,
.bold {
    font-weight: 700;
}

.font-base {
    font-family: $font__main;
}

.text-weight-medium {
    font-weight: 500;
}

.text-weight-regular {
    font-weight: 400;
}

.text-weight-light {
    font-weight: 300;
}

.text-small {
    font-size: 0.875rem;
}

.text-base {
    font-size: 1rem;
}

.italic {
    font-style: italic;
}

.font-size-h1 {
    @extend h1;
}

.font-size-h2 {
    @extend h2;
}

.font-size-h3 {
    @extend h3;
}

.font-size-h4 {
    @extend h4;
}

.font-size-h5 {
    @extend h5;
}

.font-size-h6 {
    @extend h6;
}