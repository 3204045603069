.project-link {
    position: relative;
    transition: color 0.3s linear;

    &:after {
        display: block;
        content: '';
        width: 0%;
        height: 1px;
        background-color: $color__salmon;
        transition: width 0.3s linear;
    }
    
    &:hover {
        color: $color__salmon;

        &:after {
            width: 250px;
        }
    }
}

/****************************************
Top navigation
****************************************/
.navbar-top {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-top: 75px;
    }
}

.top-navigation-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;

    a {
        color: $color__black;
    }
    
    .top-nav-title,
    .top-nav-separator,
    .top-nav-links {
        display: flex;
    }
    
    .top-nav-title {}
    
    .top-nav-separator {
        height: 1px;
        margin: 0px 40px;
        background-color: $color__black;
        flex-grow: 1;
    }
    
    .top-nav-links {}
}

.language-link {
    padding: 0px 6px;
    // border: 1px solid $color__purple;
}

/****************************************
Main navigation
****************************************/
.navbar-top {

    // position: fixed;
    // z-index: 1;
    .navigation-projects {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .project-separator {
            color: $color__black;
            font-size: 1em;
            margin: -8px 14px 0px 14px;

            @include media-breakpoint-up(md) {
                font-size: 1.5em;
                margin: 0px 30px;
            }
            
            .icon {
                // color: $color__purple;
                // --fa-primary-color: $color__purple;
                // --fa-secondary-color: limegreen;
            }
        }
    }
}