/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}



/*----------------------------------
# Images
----------------------------------*/
img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

svg{
	width: 100%;
	max-width: 100%;
	height: auto;
}

figure {
	margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}



.icon-close{
	width: 40px;

	.ligne_1,
	.ligne_2{
		transition: stroke 0.2s linear;
	}

	&:hover{
		.ligne_1,
		.ligne_2{
			stroke: $color__white;
		}
	}
}
