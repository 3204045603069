// =============================================================================
// Dependencies
// =============================================================================
// @import 'styles/variables';
// $modal-fade-transform: translate(0, 200px) ;
/* --------------------------------------------------------------
# bootstrap@5.3.1 sass files
-------------------------------------------------------------- */
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';
// 2. Include any default variable overrides here
@import 'styles/variables';
// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
// 4. Include any optional Bootstrap CSS as needed
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import "~bootstrap/scss/images";
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/helpers';
// @import '~bootstrap/scss/nav';
@import '~bootstrap/scss/accordion';
// @import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/transitions';
// @import '~bootstrap/scss/offcanvas';
// @import '~bootstrap/scss/forms/floating-labels';
// @import '~bootstrap/scss/forms/form-check';
// @import '~bootstrap/scss/forms/form-control';
// @import '~bootstrap/scss/forms/form-range';
// @import '~bootstrap/scss/forms/form-select';
// @import '~bootstrap/scss/forms/form-text.scss';
// @import '~bootstrap/scss/forms/input-group.scss';
// @import '~bootstrap/scss/forms/labels';
// @import '~bootstrap/scss/forms/validation';
// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';
/*--------------------------------------------------------------
# Fontawesome Pro 6.3
--------------------------------------------------------------*/
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
// @import '~@fortawesome/fontawesome-pro/scss/thin';
// @import '../../node_modules/@fortawesome/fontawesome-pro/scss/light';
// @import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/duotone';
@import '~@fortawesome/fontawesome-pro/scss/sharp-duotone-solid';
// @import '../../node_modules/@fortawesome/fontawesome-pro/scss/brands';
// @import '../../node_modules/@fortawesome/fontawesome-pro/scss/sharp-solid';
// @import '../../node_modules/@fortawesome/fontawesome-pro/scss/sharp-regular';
/*--------------------------------------------------------------
# Font
font-family: 'Open Sans', sans-serif;
Light : 300
Regular : 400

font-family: 'Playfair Display', serif;
Regular : 400
Regular italic : 400 italic
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Playfair+Display:ital@0;1&display=swap');
// =============================================================================
// Main
// =============================================================================
// @import
// 'styles/normalize',
// 'styles/global',
// 'styles/devtools',
// 'icon';
// =============================================================================
// Site style
// =============================================================================
/*--------------------------------------------------------------
# Typographie
--------------------------------------------------------------*/
@import 'styles/typography';
/*--------------------------------------------------------------
# Transitions
--------------------------------------------------------------*/
@import 'styles/transitions';
/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
@import 'styles/links';
/*--------------------------------------------------------------
# Medias
--------------------------------------------------------------*/
@import 'styles/medias';
/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
@import 'styles/navigation';
/*--------------------------------------------------------------
# Project
--------------------------------------------------------------*/
@import 'styles/project';
/*--------------------------------------------------------------
# Modals
--------------------------------------------------------------*/
@import 'styles/modals';