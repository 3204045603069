a{
	text-decoration: none;
	color: $color__black;
}



/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/




/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/

.btn{
	padding: 10px 20px 10px 20px;
	border: 1px solid $color__black;
	background-color: transparent;
	border-radius: 0px;

	transition: background-color 0.2s linear, color 0.2s linear;

	&:hover{
		color: $color__white;
		background-color: $color__black;
	}


	&.stroke{

	}

}
