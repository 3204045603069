

/************************************
	Modals
*************************************/

.modal-backdrop{
	background-color: transparent;
}

.modal-project{

	.modal-dialog{
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;
		width: 85%;
		max-width: none;

		margin-bottom: 0px;
		margin-right: 0px;
		margin-top: auto;

		.modal-content{
			max-height: 80%;
			height: 80%;
			border-radius: 0px;
			border: none;
			box-shadow: -8px -8px 20px rgba(0, 0, 0, 0.2);

			.modal-header,
			.modal-body,
			.modal-footer{
				border: none;
				padding: 40px;
			}




			.modal-header{
				align-items: flex-start;


				.btn-close{
					position: relative;
					height: 50px;
					width: 50px;

					&:before,
					&:after{
						position: relative;
						display: block;
						content: "";
						height: 1px;
						width: 50px;
						background-color: $color__black;
						transition: transform 0.3s linear;
					}

					&:before{
						transform: rotate(45deg);
					}

					&:after{
						transform: rotate(-45deg);
					}

					&:hover{
						&:before{
							transform: rotate(135deg);
						}

						&:after{
							transform: rotate(45deg);
						}
					}


				}

			}

			.modal-body{

			}

			.modal-footer{

			}

		}

	}


}
