/****************************************
Projects index
****************************************/
.projects-index-wrapper {
    // padding: 0px 40px;
    // padding-top: 60px;
}

.project-name {
    display: inline;

    a {
        text-decoration: none;
        color: $color__black;
        transition: $transition__color-link;
    }
    
    &:hover {
        a {
            color: var(--project_color);
        }
    }
}

.project-modal-toggler {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
}

/****************************************
Projects detail
****************************************/
.container-projects {
    margin-top: -175px;
}

.project-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 20;

    .project-out {
        flex-basis: 0%;
        cursor: url('/frontend/images/close-white.png'), crosshair;

        @include media-breakpoint-up(md) {
            flex-basis: 15%;
        }
    }
    
    .project-content {
        flex-basis: 100%;
        padding: 30px 10px;
        background-color: var(--project_color);
        box-shadow: -8px -8px 20px rgba(0, 0, 0, 0.1);

        @include media-breakpoint-up(md) {
            flex-basis: 85%;
            padding: 60px 50px;
        }
    }
}

.content-spacer {
    height: 100vh;
}