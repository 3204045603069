// ==========================================================================
// Variables
// ==========================================================================

// Breakpoints
$base:      0px;
$small:     576px;
$medium:    768px;
$ipad:      992px;
$large:     992px;
$xlarge:    1200px;
$xxlarge:   1500px;
$xxxxlarge: 1920px;

// Include-media config
$breakpoints: (
base:      $base,
small:     $small,
medium:    $medium,
ipad:      $ipad,
large:     $large,
xlarge:    $xlarge,
xxlarge:   $xxlarge,
xxxxlarge: $xxxxlarge
);



/*--------------------------------------------------------------
## Bootstrap variables
--------------------------------------------------------------*/

$grid-columns:      12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
);



/*--------------------------------------------------------------
## Colors
--------------------------------------------------------------*/

$color__white: #ffffff;
$color__black: #000000;

$color__black-80: rgba(0, 0, 0, 0.8);
$color__black-60: rgba(0, 0, 0, 0.6);
$color__black-40: rgba(0, 0, 0, 0.4);
$color__black-20: rgba(0, 0, 0, 0.2);

$color__black-soft: #2D2D2D;
$color__black-medium: #374C4F;


$color__black-night: #032B35;
$color__black-night-60: rgba(3, 43, 53, 0.6);

$color__green: #EAFFBA;
$color__blue: #BAEFFF;
$color__purple: #C5BAFF;
$color__salmon: #FFCABA;
$color__pink: #FFBACD;




/*--------------------------------------------------------------
## typography
--------------------------------------------------------------*/

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';

$font__main: 'Open Sans', sans-serif;
$font__heading: 'Playfair Display', serif;


$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.6;
$font__line-height-heading: 1.25em;
$font__line-height-pre: 1.6;


/*--------------------------------------------------------------
## transitions
--------------------------------------------------------------*/

$transition__color-link: color 0.2s linear;
$transition__opacity: opacity 0.2s linear;
$transition__color-back: background-color 0.2s linear;


/*--------------------------------------------------------------
## Structure
--------------------------------------------------------------*/

$padding__xs: 30px;
$padding__sm: 60px;
$padding__md: 80px;
$padding__lg: 100px;

$padding__navbar: 40px;
$radius__cards: 16px;


/*--------------------------------------------------------------
## elements
--------------------------------------------------------------*/

$radius-button: 4px;
$radius-tabs: 8px;
$radius-form: 8px;
